<template>
  <el-dialog
    title="Ajouter une activité"
    :visible.sync="dialogShowHcaTitreCreate"
    width="460px"
  >
    <form-hcacttitre ref="formHcatit" :catdesactive="catdesactive" />
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" type="primary" @click="hcOnSubmit"
        >Enregistrer</el-button
      >
      <el-button size="mini" @click="hcOnCancel">Annuler</el-button>
    </span>
  </el-dialog>
</template>
<script>
import FormHcacttitre from "@/components/rh/ui/form/FormHcacttitre";
import { strNormalize } from "@/fonctions";
export default {
  components: { "form-hcacttitre": FormHcacttitre },
  data() {
    return {};
  },
  props: {
    catdesactive: Boolean,
  },
  computed: {
    dialogShowHcaTitreCreate: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.comp.dialogShowHcaTitreCreate;
      },
      set(dialogShowHcaTitreCreate) {
        this.$store.commit(
          "comp/setDialogShowHcaTitreCreate",
          dialogShowHcaTitreCreate
        );
      },
    },
  },
  methods: {
    hcOnSubmit() {
      this.$refs.formHcatit.$refs.FormHcacttitre.validate((valid) => {
        if (valid) {
          // récupère les données de la boîte de dialogue
          const bd = this.$store.state.hc.hcactivite;
          // enlever les espaces avant et après dans le nom de la nouvelle activité
          bd.hcactivite_descri = bd.hcactivite_descri.trim();
          // vérifie si l'activité est  déjà dans la bd
          const HcsInBD = this.$store.getters["hc/hcactivitesInBD"];
          // nom de l'activité  en minuscule et sans espace et sans accents pour la comparaison
          const hcNormalize = strNormalize(bd.hcactivite_descri);

          if (HcsInBD.filter((m) => m[2] == hcNormalize).length > 0) {
            this.$alert(
              "Cette activité est déjà enregistrée dans la base de données !",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            //crée un nouveau enregistrement
            this.$store.dispatch("hc/createHcactivite", {
              id_hc_cat: bd.id_hc_cat,
              hcactivite_descri: bd.hcactivite_descri,
            });
            // ferme la boîte de dialogue
            this.dialogShowHcaTitreCreate = false;
          }
        } else {
          return false;
        }
      });
    },
    hcOnCancel() {
      this.dialogShowHcaTitreCreate = false;
    },
  },
};
</script>
