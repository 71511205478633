<template>
  <div class="app-form">
    <el-form
      ref="FormHcacttitre"
      :model="form"
      label-width="100px"
      label-position="right"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <!-- CHAMPS -->
      <!-- Type d'activité HC -->
      <el-form-item label="Type" prop="id_hc_cat">
        <el-select
          v-model="form.id_hc_cat"
          clearable
          filterable
          placeholder="Choisissez un type d'activité"
          :disabled="catdesactive"
        >
          <el-option
            v-for="item in catOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- Activité HC -->
      <el-form-item label="Activité" prop="hcactivite_descri">
        <el-input v-model="form.hcactivite_descri" style="width: 300px">
        </el-input>
      </el-form-item>
      <!-- Date maj -->
      <p class="updated-at" v-show="form.updated_at">
        Dernière maj :
        {{ form.updated_at }}
      </p>
      <!-- -->
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rules: {
        id_hc_cat: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        hcactivite_descri: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    catdesactive: Boolean,
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.hc.hcactivite;
      },
      set(form) {
        this.$store.commit("hc/setHcactivite", form);
      },
    },
    catOptions() {
      return this.$store.getters["hccat/categorieSelect"];
    },
  },
};
</script>
