<template>
  <el-main class="rh-medform">
    <form-hcactiviteedit :ismeddetail="true" :routetolist="hcmRouteToList" />
  </el-main>
</template>
<script>
import FormHcactiviteedit from "@/components/rh/formulaires/FormHcactiviteedit";

export default {
  components: { "form-hcactiviteedit": FormHcactiviteedit },
  computed: {
    hcmRouteToList() {
      return {
        name: "med_hcactivites",
        params: {
          idmed: this.$route.params.idmed,
        },
      };
    },
  },
};
</script>
