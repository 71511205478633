<template>
  <div class="app-form">
    <el-form
      ref="formHcactivite"
      :model="form"
      label-width="100px"
      label-position="right"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <!-- CHAMPS -->
      <el-row>
        <el-col :span="12">
          <el-form-item label="Médecin" prop="id_med" v-show="!ismeddetail">
            <el-select
              v-model="form.id_med"
              clearable
              filterable
              placeholder="Choisissez un médecin"
            >
              <el-option
                v-for="item in medecinOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Type" prop="type">
            <el-radio-group
              v-model="type"
              size="small"
              style="width: 314px"
              @change="resetActivite"
            >
              <el-radio-button
                v-for="opt in catOptions"
                :key="opt.value"
                :label="opt.value"
                >{{ opt.label }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Activité" prop="id_hcactivite">
            <el-select
              ref="hcactivite"
              v-model="form.id_hcactivite"
              :disabled="desactive"
              clearable
              filterable
              placeholder="Choisissez une activité"
            >
              <el-option
                v-for="item in hcactiviteOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
              <el-option value="">
                <el-button type="text" @click="addHc">
                  <i class="el-icon-circle-plus-outline"></i
                  ><i> Ajouter une activité</i>
                </el-button>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Mois de début" prop="debut_date">
            <el-date-picker
              v-model="form.debut_date"
              type="month"
              value-format="yyyy-MM"
              placeholder="Choisissez un mois"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="Mois de fin" prop="fin_date">
            <el-date-picker
              v-model="form.fin_date"
              type="month"
              value-format="yyyy-MM"
              placeholder="Choisissez un mois"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- boîte de dialogue pour ajouter une activité hors-clinique -->
    <dialogform-hcacttitrecreate :catdesactive="true" />
  </div>
</template>
<script>
import DialogformHcacttitrecreate from "@/components/rh/dialogbox/DialogformHcacttitrecreate";

export default {
  components: { "dialogform-hcacttitrecreate": DialogformHcacttitrecreate },

  data() {
    var valideDebut = (rule, value, callback) => {
      if (value) {
        callback();
        //this.$refs.formHcactivite.validateField('fin_date');
      } else {
        callback(new Error("Ce champ est obligatoire"));
      }
    };
    var valideFin = (rule, value, callback) => {
      if (value) {
        if (this.form.debut_date && this.form.debut_date >= value) {
          callback(
            new Error("Le mois de fin doit être postérieure au mois de début !")
          );
        } else {
          callback();
        }
      } else {
        callback();
        //callback(new Error('Ce champ est obligatoire'));
      }
    };

    return {
      type: null,
      desactive: true,
      rules: {
        id_med: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        id_hcactivite: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        debut_date: [
          { required: true, validator: valideDebut, trigger: "change" },
        ],
        fin_date: [
          { required: false, validator: valideFin, trigger: "change" },
        ],
      },
    };
  },
  props: {
    ismeddetail: Boolean,
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.hcm.hcactivitemedecin;
      },
      set(form) {
        this.$store.commit("hcm/setHcactivitemedecin", form);
      },
    },
    dialogShowHcaTitreCreate: {
      get() {
        return this.$store.state.comp.dialogShowHcaTitreCreate;
      },
      set(dialogShowHcaTitreCreate) {
        this.$store.commit(
          "comp/setDialogShowHcaTitreCreate",
          dialogShowHcaTitreCreate
        );
      },
    },
    medecinOptions() {
      return this.$store.getters["medl/medecinSelect"];
    },
    catOptions() {
      return this.$store.getters["hccat/categorieSelect"];
    },
    hcactiviteOptions() {
      const hcactivites = this.$store.getters["hc/hcactiviteSelect"];
      return this.type ? hcactivites.filter((o) => o.type == this.type) : [];
    },
    hcactiviteCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.hc.hcactiviteCreateStatus;
    },
    hcmedIsLoaded() {
      return (
        this.$store.state.hcm.hcactivitemedecinLoadStatus == 2 &&
        this.$store.state.hc.hcactivitesLoadStatus == 2
      );
    },
    idHcactivite() {
      return this.form.id_hcactivite;
    },
  },

  methods: {
    setType() {
      const idHc = this.form.id_hcactivite;
      if (idHc) {
        // toutes les hcactivités avec leur type
        const hcactivites = this.$store.getters["hc/hcactiviteSelect"];
        // récupère le id du type
        this.type = hcactivites.filter((a) => a.value == idHc)[0].type;
      }
      // active 'Activité' si un type est choisi
      this.desactive = this.type ? false : true;
    },
    resetActivite() {
      const opt = this.hcactiviteOptions;
      // si une seule option, la sélectionne
      const act = opt.length == 1 ? opt[0].value : null;
      // vide le champs 'activité' sur changement de 'type'
      this.$refs.hcactivite.$emit("input", act);
      // active 'Activité' si un type est choisi
      this.desactive = this.type ? false : true;
    },
    addHc() {
      this.dialogShowHcaTitreCreate = true;
      this.$store.commit("hc/resetHcactivite", this.type);
    },
  },
  watch: {
    hcmedIsLoaded() {
      // watch le chargement de l'activité HC du médecin et aussi celui de Hcactivites si charge directement la page: 2 + 2
      if (this.hcmedIsLoaded) {
        this.setType();
      }
    },
    idHcactivite() {
      // maj le type d'activité sélectionné quand annule changement de type
      this.setType();
    },
    hcactiviteCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      // et l'affichage de la nouvelle activité dans le dropdown
      if (this.hcactiviteCreateStatus == 2) {
        // message succès
        this.$message({
          message: "L'activité a été ajoutée à la base de donnée",
          type: "success",
        });
        // selectionne l'activité qui vient d'être créée
        this.form.id_hcactivite = this.$store.state.hc.hcactiviteLastCreatedId;
      } else if (this.hcactiviteCreateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
